import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'

import { CustomTypeOptions } from 'react-i18next'

import { unOrphan } from '@/utils/orphans'

import { Container, GridSeo, Line, Text } from './SeoDescription.styled'

interface Props {
  name: keyof CustomTypeOptions['resources']['parkings']['seoDescription']
}

function SeoDescription(props: Props) {
  const { t } = useTranslation('parkings')
  const { name } = props

  return (
    <Container>
      <GridSeo>
        <Line />
        <Text as="div">{parse(unOrphan(t(`seoDescription.${name}`)))}</Text>
        <Line />
      </GridSeo>
    </Container>
  )
}

export default SeoDescription
