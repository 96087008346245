import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const button = css`
  position: relative;
  height: 24px;
  bottom: -40px;
`
export const TitleWrapper = styled.div`
  width: 100%;
`
