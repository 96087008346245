import React, { ReactNode } from 'react'

import HeroWrapper from '@/components/common/HeroWrapper'
import Title from '@/components/common/Title'

import ScrollArrow from '@/components/common/ScrollArrow'

import { TitleWrapper, button } from './HeroCity.styled'

interface Props {
  text: string | ReactNode
}

function HeroCity(props: Props) {
  const { text } = props

  return (
    <HeroWrapper>
      <TitleWrapper>
        <Title text={text} primary animated />
      </TitleWrapper>

      <div css={button}>
        <ScrollArrow anchor="parkings-section" light />
      </div>
    </HeroWrapper>
  )
}

export default HeroCity
