import styled from '@emotion/styled'

import { LegiaMedium, Text2 } from '@/styles/typography'
import { Grid, mediaColumns } from '@/styles/grid'
import { colors } from '@/styles/theme'
import { mediaDown, mediaUp } from '@/styles/media'

export const Container = styled.section`
  padding: 18px 0;
  margin-bottom: 60px;

  ${mediaUp('lg')} {
    margin-bottom: 80px;
    padding: 40px 0;
  }
`

export const GridSeo = styled(Grid)`
  position: relative;
`

export const Line = styled.div`
  position: absolute;
  width: calc(100% - 32px);
  height: 2px;
  background-color: ${colors.twilightBlue};
  opacity: 0.08;
  left: 50%;
  transform: translateX(-50%);

  &:first-of-type {
    top: -18px;
  }

  &:last-child {
    bottom: -18px;
  }

  ${mediaUp('md')} {
    width: calc(100% - 60px);
  }

  ${mediaUp('lg')} {
    width: calc(100% - 100px);

    &:first-of-type {
      top: -40px;
    }

    &:last-child {
      bottom: -40px;
    }
  }

  ${mediaUp('xl')} {
    width: calc(100% - 160px);
  }
`

export const Text = styled(Text2)`
  ${mediaColumns({ xs: [12, 0] })};

  ul {
    padding-inline-start: 50px;

    ${mediaDown('md')} {
      padding-inline-start: 25px;
    }
  }

  p,
  ul,
  h3 {
    margin-bottom: 1.2em;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  .legia-warszawa & {
    ${LegiaMedium};
    color: var(--mainCustom);
  }
`
