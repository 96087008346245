import { Container, Content, GridWrapper } from './HeroWrapper.styled'

interface Props {
  children: React.ReactNode
  withoutContent?: boolean
  className?: string
  noPadding?: boolean
}

function HeroWrapper(props: Props) {
  const { children, withoutContent, className, noPadding = false } = props

  return (
    <Container className={className}>
      <GridWrapper noPadding={noPadding}>
        {!withoutContent ? <Content>{children}</Content> : children}
      </GridWrapper>
    </Container>
  )
}

export default HeroWrapper
