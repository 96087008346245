import { GetServerSidePropsContext } from 'next'
import { useTranslation } from 'next-i18next'

import dynamic from 'next/dynamic'

import { useMarket } from '@/utils/multi-markets/context'

import Meta from '@/components/Meta'
import Results from '@/components/parkings/Results'
import HeroCity from '@/components/parkings/HeroCity/HeroCity'
import SeoDescription from '@/components/parking/SeoDescription'
import HaveParking from '@/components/sections/HaveParking'
import ContactSection from '@/components/sections/ContactSection'
import Partners from '@/components/sections/Partners'
import CustomReveal from '@/components/common/CustomReveal'
import { loadTranslationsWithMarket } from '@/utils/loadTranslations'
import DefaultLayout from '@/components/layouts/Default'
import { useFetchParkings } from '@/service/apiHooks/useFetchParkings'

const MapView = dynamic(() => import('@/components/parkings/MapView'), {
  ssr: false
})

export const getServerSideProps = (context: GetServerSidePropsContext) => {
  return loadTranslationsWithMarket(context, {
    cache: 'stale-while-revalidate',
    namespaces: ['common', 'parkings']
  })
}

function ParkAndFly() {
  const { t } = useTranslation('parkings')
  const { market, locale } = useMarket()
  const { parkings } = useFetchParkings({
    params: {
      lang: locale,
      countryCode: market,
      infoTag: 'PARK_AND_FLY',
      size: 100
    }
  })

  return (
    <DefaultLayout>
      <Meta
        title={t(`meta.${market}.title` as any)}
        description={t(`meta.${market}.description` as any)}
      />
      <HeroCity text={t(`hero.parkAndFly` as any)} />

      <CustomReveal>
        {parkings ? (
          <div id="parkings-section">
            <Results parkings={parkings} />
            <MapView parkings={parkings} />
          </div>
        ) : null}
      </CustomReveal>

      <HaveParking />
      {t(`seoDescription.${market}` as any).length > 50 ? (
        <SeoDescription name={market as any} />
      ) : (
        <></>
      )}
      <ContactSection />
      <Partners />
    </DefaultLayout>
  )
}

export default ParkAndFly
