import styled from '@emotion/styled'

import { colors, vars } from '@/styles/theme'
import { Grid, mediaColumns } from '@/styles/grid'
import { mediaUp } from '@/styles/media'

export const Container = styled.section`
  position: relative;
  background-color: ${colors.paleGrey};
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 1;
`

export const Content = styled.div`
  ${mediaColumns({ xs: [12, 0], md: [8, 2] })};
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
`

export const GridWrapper = styled(Grid)<{ noPadding: boolean }>`
  margin-top: ${vars.headerHeightMobile};
  padding-top: ${({ noPadding }) => (noPadding ? '0' : '10px')};
  padding-bottom: ${({ noPadding }) => (noPadding ? '0' : '28px')};

  ${mediaUp('md')} {
    padding-top: 20px;
    padding-bottom: 60px;
  }

  ${mediaUp('lg')} {
    padding-top: 20px;
    padding-bottom: 80px;
    margin-top: ${vars.headerHeight};
  }
`
